import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" fill="none" {...props}>
        <path d="M7.26636 5.73397C6.84329 5.3109 6.15736 5.3109 5.73429 5.73396C5.31123 6.15703 5.31123 6.84296 5.73429 7.26603L11.4683 13L5.73432 18.734C5.31126 19.157 5.31126 19.843 5.73432 20.266C6.15739 20.6891 6.84332 20.6891 7.26639 20.266L13.0003 14.5321L18.7343 20.266C19.1574 20.6891 19.8433 20.6891 20.2664 20.266C20.6894 19.843 20.6894 19.157 20.2664 18.734L14.5324 13L20.2664 7.26603C20.6895 6.84296 20.6895 6.15703 20.2664 5.73397C19.8433 5.3109 19.1574 5.3109 18.7343 5.73397L13.0003 11.468L7.26636 5.73397Z" fill="#23262F"/>
    </Svg>
  );
};

export default Icon;
