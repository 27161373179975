import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { Box, Flex } from "../../../../components/Box";
import { ChevronDownIcon } from "../../../../components/Svg";
import { UserMenuProps, variants } from "./types";
import MenuIcon from "./MenuIcon";
import { UserMenuItem } from "./styles";

export const StyledUserMenu = styled(Flex)<{isShow?:boolean}>`
  align-items: center;
  background-color:transparent;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  padding-left: ${({ isShow }) => isShow ? "32px" : "8px"};
  padding-right: 4px;
  position: relative;
  @media screen and (max-width: 600px){
    height: 32px;
  }
  &:hover {
    opacity: 0.65;
  }
`;

export const LabelText = styled.div<{isShowText?:boolean}>`
  color: ${({ theme }) => theme.colors.wrapHeader};
  font-style: normal;
  font-weight: 700;
  display: ${({ isShowText }) => !isShowText ? "block" : "none" };
  font-size: 14px;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 4px 24px 4px rgba(16, 16, 16, 0.25);
  border-radius: 0px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  width: 280px;
  padding:12px;
  visibility: visible;
  z-index: 1001;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`;

const UserMenu: React.FC<UserMenuProps> = ({
  isSwitchNetwork,
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  disabled,
  placement = "bottom-end",
  isShowIcon=!false,
  isHiddenText,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const [accountEllipsis, setAccountEllipsis] = useState<string | null>(null);
  useEffect(()=>{
    if(window.screen.width < 321){
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 2)}` : null;
      setAccountEllipsis(accountEllipsis)
    }
    if(window.screen.width > 322){
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;
      setAccountEllipsis(accountEllipsis)
    }
  },[window.screen.width, account])
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: "fixed",
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <CsFlex alignItems="center" 
      background={isHiddenText ? "transparent" :"linear-gradient(90deg, #F1A40D 0.12%, #FEEA38 100.12%)"} 
      ref={setTargetRef} {...props}
    >
      <StyledUserMenu
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
        isShow={isShowIcon}
      >
        { isShowIcon && 
          <MenuIcon avatarSrc={avatarSrc} variant={variant} />
        }
        { !isHiddenText &&
            <LabelText isShowText={isShowIcon} title={typeof text === "string" ? text || account : account}>{text || accountEllipsis}</LabelText>
        }
        {!disabled && <ChevronDownIcon color={isSwitchNetwork ? "white" : "text"} style={{display: isSwitchNetwork ? "block" : "none", marginLeft: '2px' }} width="20px" />}
      </StyledUserMenu>
      {!disabled && (
        <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
        </Menu>
      )}
    </CsFlex>
  );
};

const CsFlex = styled(Flex)`
  height: 100%;
  border-radius: 4px;
  align-items: center;
  @media screen and (max-width: 600px){
    height: 35px;
  }
`

export default UserMenu;
