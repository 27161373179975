import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="20" height="20" viewBox="0 0 20 20" style={{fill:"none"}} {...props}>
<path d="M15.1166 18.0166C14.3833 18.2333 13.5166 18.3333 12.5 18.3333H7.49997C6.4833 18.3333 5.61663 18.2333 4.8833 18.0166C5.06663 15.85 7.29163 14.1416 9.99997 14.1416C12.7083 14.1416 14.9333 15.85 15.1166 18.0166Z" stroke="#FF592C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 15.6503 2.6165 17.3753 4.88317 18.017C5.0665 15.8503 7.2915 14.142 9.99984 14.142C12.7082 14.142 14.9332 15.8503 15.1165 18.017C17.3832 17.3753 18.3332 15.6503 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699ZM9.99984 11.8086C8.34984 11.8086 7.0165 10.467 7.0165 8.81701C7.0165 7.16701 8.34984 5.83366 9.99984 5.83366C11.6498 5.83366 12.9832 7.16701 12.9832 8.81701C12.9832 10.467 11.6498 11.8086 9.99984 11.8086Z" stroke="#FF592C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.9833 8.81636C12.9833 10.4664 11.6499 11.808 9.99994 11.808C8.34994 11.808 7.0166 10.4664 7.0166 8.81636C7.0166 7.16636 8.34994 5.83301 9.99994 5.83301C11.6499 5.83301 12.9833 7.16636 12.9833 8.81636Z" stroke="#FF592C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

export default Icon;
