import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET } from './common'

export const bscTestnetTokens = {
  wbnb: WBNB[ChainId.BSC_TESTNET],
  cake: CAKE_TESTNET,
  busd: BUSD_TESTNET,
  syrup: new Token(
    ChainId.BSC_TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    ChainId.BSC_TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
  hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
  wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 8, 'WBTC', 'Wrapped BTC'),
  usdc: new Token(
    ChainId.BSC_TESTNET,
    '0xF50670eFA48f8e0f01121C8BA1F6bb5162754Ac1',
    18,
    'USDC',
    'Binance-Peg USD Coin',
  ),
  runtogether: new Token(
    ChainId.BSC_TESTNET,
    '0x242a227B38f704983FF101DC7De573ED8111601e',
    18,
    'RUN ',
    'Run Together',
    'https://runtogether.net/',
  ),
  runtogetherBoxNft: new Token(
    ChainId.BSC_TESTNET,
    '0x00fbf2a59c65d495055dee6e3925ff8d7c0bdad6',
    0,
    'RTBN',
    'Run Together Box NFT',
    'https://runtogether.net/',
  ),
  pib: new Token(
    ChainId.BSC_TESTNET,
    '0x30f1917E5ea3046b336BD2f7AA2F9A1b90F5Ae5F',
    18,
    'PiB',
    'PiBridge',
    'https://pibridge.org/#',
  ),
  wpi: new Token(
    ChainId.BSC_TESTNET,
    '0x68b899f59321aC80063f4f7DC52b0d5C35752E4C',
    18,
    'WPi',
    'Wrapped Pi',
    'https://pibridge.org/#',
  ),
  usdt: new Token(
    ChainId.BSC_TESTNET,
    '0x3a91dc619757f89ca11dc18ec9b93699b75bca07',
    18,
    'USDT',
    'USDT Token',
    'https://tether.to/',
  ),
  pibridgeNft: new Token(
    ChainId.BSC_TESTNET,
    '0x64Bc32b8BE2C81cbB6f2742Fd0820e1BB807F758',
    0,
    'PiBN',
    'PiBridgeMinerNft',
    'https://pibridge.org/',
  ),
  nftPiBridgeOld: new Token(
    ChainId.BSC_TESTNET,
    '0x5736a8979e7a0B4017aDf8A83154D52EBB9cB264',
    0,
    'PiBN',
    'PiBridgeMinerNft',
    'https://pibridge.org/',
  ),
  pibridgeNftV2: new Token(
    ChainId.BSC_TESTNET,
    '0x56Eb879B37BB25032aBb083a3580C4753b6805b6',
    0,
    'PiBN',
    'PiBridgeMinerNft',
    'https://pibridge.org/',
  ),
  VNDC: new Token(
    ChainId.BSC_TESTNET,
    '0x786f7ec7f3fFdd60d51981E69df28Da4b855B8f1',
    18,
    'VNDC',
    'VNDC Token',
    '',
  ),
}
