import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0x81fc0da5d4e981eb86168b2f838ae0914ec8d38f',
    56: '0xf489022c5e9b973d39a93f96864cf24b28f23023',
    1945: '0xfC6412C60db06DE222538d51f0585933153dB9a5',
    1975: '0xe5485c394D8d1Ce80d0D648a4c6DC9c749dda7cb',
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  multiCall: multicallAddresses,
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    97: '',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },

  AnniversaryAchievement: {
    56: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },

  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },

  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  },
  bCakeFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    97: '',
  },
  bCakeFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    97: '',
  },
  nonBscVault: {
    56: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841', // Only for pass contracts test
    1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
  },
  crossFarmingSender: {
    56: '0x327d26dE30f92600620A99043034e0A5FD9402C8', // Only for pass contracts test
    1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
  },
  crossFarmingReceiver: {
    56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
    97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
  },
}
