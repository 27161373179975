import React from 'react'

const Icon: React.FC = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6025 1.66675 10.0001 1.66675C5.39771 1.66675 1.66675 5.39771 1.66675 10.0001C1.66675 14.6025 5.39771 18.3334 10.0001 18.3334ZM14.8739 8.20719C15.2644 7.81666 15.2644 7.1835 14.8739 6.79297C14.4833 6.40245 13.8502 6.40245 13.4596 6.79297L9.16675 11.0859L7.37385 9.29297C6.98333 8.90245 6.35017 8.90245 5.95964 9.29297C5.56912 9.6835 5.56912 10.3167 5.95964 10.7072L8.45964 13.2072C8.85017 13.5977 9.48333 13.5977 9.87385 13.2072L14.8739 8.20719Z"
        fill="#45B36B"
      />
    </svg>
  )
}

export default Icon
