import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="109" height="24" viewBox="0 0 109 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M21.5599 10.7405L20.1999 9.16055C19.9399 8.86055 19.7299 8.30055 19.7299 7.90055V6.20055C19.7299 5.14055 18.8599 4.27055 17.7999 4.27055H16.0999C15.7099 4.27055 15.1399 4.06055 14.8399 3.80055L13.2599 2.44055C12.5699 1.85055 11.4399 1.85055 10.7399 2.44055L9.16988 3.81055C8.86988 4.06055 8.29988 4.27055 7.90988 4.27055H6.17988C5.11988 4.27055 4.24988 5.14055 4.24988 6.20055V7.91055C4.24988 8.30055 4.03988 8.86055 3.78988 9.16055L2.43988 10.7505C1.85988 11.4405 1.85988 12.5605 2.43988 13.2505L3.78988 14.8405C4.03988 15.1405 4.24988 15.7005 4.24988 16.0905V17.8005C4.24988 18.8605 5.11988 19.7305 6.17988 19.7305H7.90988C8.29988 19.7305 8.86988 19.9405 9.16988 20.2005L10.7499 21.5605C11.4399 22.1505 12.5699 22.1505 13.2699 21.5605L14.8499 20.2005C15.1499 19.9405 15.7099 19.7305 16.1099 19.7305H17.8099C18.8699 19.7305 19.7399 18.8605 19.7399 17.8005V16.1005C19.7399 15.7105 19.9499 15.1405 20.2099 14.8405L21.5699 13.2605C22.1499 12.5705 22.1499 11.4305 21.5599 10.7405ZM16.1599 10.1105L11.3299 14.9405C11.1899 15.0805 10.9999 15.1605 10.7999 15.1605C10.5999 15.1605 10.4099 15.0805 10.2699 14.9405L7.84988 12.5205C7.55988 12.2305 7.55988 11.7505 7.84988 11.4605C8.13988 11.1705 8.61988 11.1705 8.90988 11.4605L10.7999 13.3505L15.0999 9.05055C15.3899 8.76055 15.8699 8.76055 16.1599 9.05055C16.4499 9.34055 16.4499 9.82055 16.1599 10.1105Z" fill="#5DCB83"/>
        <path d="M42.5 11.682C42.5 7.92 39.656 5.256 36.074 5.256C32.528 5.256 29.63 7.92 29.63 11.682C29.63 15.462 32.528 18.126 36.074 18.126C39.62 18.126 42.5 15.462 42.5 11.682ZM32.222 11.682C32.222 9.126 33.788 7.524 36.074 7.524C38.342 7.524 39.908 9.126 39.908 11.682C39.908 14.22 38.342 15.876 36.074 15.876C33.788 15.876 32.222 14.22 32.222 11.682ZM46.8948 11.142V7.488H48.8928C50.3328 7.488 50.9448 8.172 50.9448 9.324C50.9448 10.458 50.3328 11.142 48.8928 11.142H46.8948ZM53.5368 9.324C53.5368 7.146 52.0068 5.436 49.0008 5.436H44.3748V18H46.8948V13.176H49.0008C52.2408 13.176 53.5368 11.25 53.5368 9.324ZM62.616 5.418H55.326V18H62.616V15.948H57.846V12.618H62.076V10.62H57.846V7.47H62.616V5.418ZM73.1321 5.418V14.058L67.4261 5.418H64.9061V18H67.4261V9.378L73.1321 18H75.6521V5.418H73.1321ZM78.1424 18H80.6624V5.436H78.1424V18ZM91.3782 5.418V14.058L85.6722 5.418H83.1522V18H85.6722V9.378L91.3782 18H93.8982V5.418H91.3782ZM102.184 5.274C98.5305 5.274 95.7765 7.938 95.7765 11.7C95.7765 15.462 98.5305 18.108 102.202 18.108C105.586 18.108 107.8 15.768 108.34 13.212V11.016H101.536V12.942H105.856C105.478 14.742 104.236 15.84 102.292 15.84C99.9525 15.84 98.3685 14.238 98.3685 11.7C98.3685 9.198 99.9345 7.596 102.184 7.596C103.534 7.596 104.56 8.136 105.136 9.216H108.034C107.206 6.696 104.992 5.274 102.184 5.274Z" fill="#5DCB83"/>
    </Svg>
  );
};

export default Icon;
