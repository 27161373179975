import React from "react";
import styled, { DefaultTheme } from "styled-components";
import CheckmarkCircleIcon from "../Svg/Icons/CheckmarkCircle";
import ErrorIcon from "../Svg/Icons/Error";
import BlockIcon from "../Svg/Icons/Block";
import InfoIcon from "../Svg/Icons/Info";
import { Text } from "../Text";
import { IconButton } from "../Button";
import { CloseIconV2 } from "../Svg";
import Flex from "../Box/Flex";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
  variant: AlertProps["variant"];
  theme: DefaultTheme;
  hasDescription?: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return theme.colors.failure;
    case variants.WARNING:
      return theme.colors.warning;
    case variants.SUCCESS:
      return theme.colors.success;
    case variants.INFO:
    default:
      return theme.colors.secondary;
  }
};

const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return BlockIcon;
    case variants.WARNING:
      return ErrorIcon;
    case variants.SUCCESS:
      return CheckmarkCircleIcon;
    case variants.INFO:
    default:
      return InfoIcon;
  }
};

const IconLabel = styled.div`
  background: rgba(255, 255, 255, 0.08);
  display:flex;
  align-items:center;
  height: 52px;
  width: 52px;
  justify-content: center;
  border-radius: 50%;
  margin-left:12px;

`;

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "12px")};
`;

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  right: 8px;
  position: absolute;
  top: 30%;
`;

const StyledAlert = styled(Flex)<ThemedIconLabel>`
    position: relative;
    background: ${({ theme }) => theme.colors.white};
    border-top: 2px solid ${getThemeColor};
    border-bottom: 2px solid ${getThemeColor};
    flex-direction:column;
    padding-bottom: 10px;
    padding-top: 12px;
`;

const Diverder = styled(Flex)`
    width: 100%;
    border: 1px dashed #808080;
`;

const CsSvg = styled.svg<ThemedIconLabel>`
    fill: ${getThemeColor};
`


const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({ title, children, variant, onClick }) => {
  const Icon = getIcon(variant);

  return (
    <StyledAlert variant={variant}>
      <Flex>
          <IconLabel>
            <Icon color="currentColor" width="24px" />
          </IconLabel>
          <Details hasHandler={!!onClick}>
            <Text color="text" bold>{title}</Text>
            {typeof children === "string" ? <Text as="p">{children}</Text> : children}
          </Details>
          {onClick && (
            <CloseHandler>
              <IconButton scale="sm" variant="text" onClick={onClick}>
                <CloseIconV2 width="24px" color="currentColor" />
              </IconButton>
            </CloseHandler>
          )}
      </Flex>
      <Flex width="100%" alignItems="center">
        <CsSvg variant={variant} xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 11 22" fill="none">
            <path d="M0 22V0L8.85247 8.5205C10.5441 10.1487 10.4761 12.877 8.70548 14.419L0 22Z"/>
        </CsSvg>
        <Diverder/>
      </Flex>
    </StyledAlert>
  );
};

export default Alert;
