/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import styled from "styled-components";
import { Text } from "../Text";
import { Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
import { MoreInformation } from "./config";
import {
  ContainerContact,
  ContainerInfo,
  ContainerMoreInformation,
  CsTextEmail,
  CustomFlex,
  CustomLink,
  HeadTitle,
  Img,
  StyledFooter
} from "./styles";
import { FooterProps } from "./types";
import { Heading } from "../Heading";

const MenuItem: React.FC<FooterProps> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  ...props
}) => {
  return (
    <StyledFooter alignItems="center" flexDirection="column" {...props} justifyContent="center">
      <Flex 
          maxWidth="1320px"
          px={['16px', '10px']}
          flexDirection="column"
          width="100%"
        >
          <Flex
            width="100%"
            background="linear-gradient(90deg, #F1A30C 0%, #FEEA38 100%)"
            height="1px"
            
          />
          <Flex width="100%" justifyContent="center" alignItems="center" height="90px">
              <CsHeading scale="md" color="#A6A6A6" fontWeight={600}>Copyright © 2023 VNDC. All rights reserved.</CsHeading>
          </Flex>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;

const CsHeading = styled(Heading)`
   @media screen and (max-width:375px) {
      font-size: 12px !important;
   }
`