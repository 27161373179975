import axios from 'axios';

// Add a request interceptor
export default function configAxios() {
    axios.defaults.baseURL = process.env.NEXT_PUBLIC_API
    axios.defaults.headers["content-type"] = "application/json";
    axios.defaults.timeout = 10000;
    
    axios.interceptors.request.use(
        async (config) => {
            return config
        },
        (error) => {
            Promise.reject(error)
        },
    )

    axios.interceptors.response.use(
        (response) => {
            return response
        },
        // eslint-disable-next-line func-names
        async function (error) {
            if (!navigator.onLine) {
                return Promise.reject(error)
            }
            const originalRequest = error.config
            if (
                error &&
                error.response &&
                error?.response?.status === 401 &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true
            }
            return Promise.reject(error)
        },
    )
}

