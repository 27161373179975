import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="100" height="24" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M21.5599 10.7381L20.2099 9.15812C19.9599 8.85812 19.7499 8.29813 19.7499 7.89813V6.19812C19.7499 5.13812 18.8799 4.26812 17.8199 4.26812H16.1199C15.7199 4.26812 15.1499 4.05812 14.8499 3.80812L13.2699 2.45812C12.5799 1.86813 11.4499 1.86813 10.7599 2.45812L9.15988 3.80812C8.85988 4.05812 8.29988 4.26812 7.89988 4.26812H6.16988C5.10988 4.26812 4.23988 5.13812 4.23988 6.19812V7.89813C4.23988 8.28813 4.03988 8.84812 3.78988 9.14812L2.43988 10.7381C1.85988 11.4381 1.85988 12.5581 2.43988 13.2381L3.78988 14.8281C4.03988 15.1181 4.23988 15.6881 4.23988 16.0781V17.7881C4.23988 18.8481 5.10988 19.7181 6.16988 19.7181H7.90988C8.29988 19.7181 8.86988 19.9281 9.16988 20.1781L10.7499 21.5281C11.4399 22.1181 12.5699 22.1181 13.2599 21.5281L14.8399 20.1781C15.1399 19.9281 15.6999 19.7181 16.0999 19.7181H17.7999C18.8599 19.7181 19.7299 18.8481 19.7299 17.7881V16.0881C19.7299 15.6881 19.9399 15.1281 20.1899 14.8281L21.5399 13.2481C22.1499 12.5681 22.1499 11.4381 21.5599 10.7381ZM11.2499 8.12813C11.2499 7.71813 11.5899 7.37813 11.9999 7.37813C12.4099 7.37813 12.7499 7.71813 12.7499 8.12813V12.9581C12.7499 13.3681 12.4099 13.7081 11.9999 13.7081C11.5899 13.7081 11.2499 13.3681 11.2499 12.9581V8.12813ZM11.9999 16.8681C11.4499 16.8681 10.9999 16.4181 10.9999 15.8681C10.9999 15.3181 11.4399 14.8681 11.9999 14.8681C12.5499 14.8681 12.9999 15.3181 12.9999 15.8681C12.9999 16.4181 12.5599 16.8681 11.9999 16.8681Z" fill="#F59D19"/>
        <path d="M29.63 11.7C29.63 15.462 32.384 18.108 36.002 18.108C38.81 18.108 41.042 16.632 41.906 14.04H39.008C38.414 15.264 37.352 15.858 35.984 15.858C33.77 15.858 32.222 14.22 32.222 11.7C32.222 9.144 33.77 7.542 35.984 7.542C37.352 7.542 38.414 8.118 39.008 9.342H41.906C41.042 6.75 38.81 5.274 36.002 5.274C32.384 5.274 29.63 7.938 29.63 11.7ZM44.0584 5.436V18H50.7184V16.002H46.5784V5.436H44.0584ZM64.5781 11.682C64.5781 7.92 61.7341 5.256 58.1521 5.256C54.6061 5.256 51.7081 7.92 51.7081 11.682C51.7081 15.462 54.6061 18.126 58.1521 18.126C61.6981 18.126 64.5781 15.462 64.5781 11.682ZM54.3001 11.682C54.3001 9.126 55.8661 7.524 58.1521 7.524C60.4201 7.524 61.9861 9.126 61.9861 11.682C61.9861 14.22 60.4201 15.876 58.1521 15.876C55.8661 15.876 54.3001 14.22 54.3001 11.682ZM75.2369 14.364C75.2369 9.918 68.7929 11.34 68.7929 8.82C68.7929 7.794 69.5129 7.29 70.5209 7.344C71.6009 7.362 72.2669 7.992 72.3209 8.838H75.0929C74.9309 6.552 73.1849 5.256 70.5929 5.256C68.0009 5.256 66.1649 6.642 66.1649 8.892C66.1649 13.428 72.6269 11.808 72.6269 14.526C72.6269 15.444 71.9429 16.056 70.7369 16.056C69.5669 16.056 68.9009 15.39 68.8289 14.382H66.1289C66.1829 16.758 68.1809 18.126 70.7909 18.126C73.6709 18.126 75.2369 16.344 75.2369 14.364ZM84.7117 5.418H77.4217V18H84.7117V15.948H79.9417V12.618H84.1717V10.62H79.9417V7.47H84.7117V5.418ZM87.0018 5.436V18H91.3938C95.4258 18 98.0178 15.552 98.0178 11.736C98.0178 7.92 95.4258 5.436 91.3938 5.436H87.0018ZM89.5218 15.858V7.56H91.3038C93.9678 7.56 95.4438 9.126 95.4438 11.736C95.4438 14.346 93.9678 15.858 91.3038 15.858H89.5218Z" fill="#F59D19"/>
    </Svg>
  )
}

export default Icon
