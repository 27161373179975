import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M15.8332 7.5C15.8332 7.96024 16.2063 8.33333 16.6665 8.33333C17.1267 8.33333 17.4998 7.96024 17.4998 7.5V4.16667C17.4998 3.24619 16.7536 2.5 15.8332 2.5H12.4998C12.0396 2.5 11.6665 2.8731 11.6665 3.33333C11.6665 3.79357 12.0396 4.16667 12.4998 4.16667L14.6547 4.16667L9.41058 9.41075C9.08514 9.73618 9.08514 10.2638 9.41058 10.5893C9.73602 10.9147 10.2637 10.9147 10.5891 10.5893L15.8332 5.34518V7.5Z" fill="#3D3D3D" fillOpacity="0.62"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H9.16667C9.6269 2.5 10 2.8731 10 3.33333C10 3.79357 9.6269 4.16667 9.16667 4.16667H5C4.53976 4.16667 4.16667 4.53976 4.16667 5V15C4.16667 15.4602 4.53976 15.8333 5 15.8333H15C15.4602 15.8333 15.8333 15.4602 15.8333 15V10.8333C15.8333 10.3731 16.2064 10 16.6667 10C17.1269 10 17.5 10.3731 17.5 10.8333V15C17.5 16.3807 16.3807 17.5 15 17.5H5C3.61929 17.5 2.5 16.3807 2.5 15V5Z" fill="#3D3D3D"/>
    </Svg>
  );
};

export default Icon;
