import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33332 0.333252H16.6667C18.0474 0.333252 19.1667 1.45254 19.1667 2.83325V3.18945C19.1668 3.19672 19.1668 3.204 19.1667 3.21127V11.9999C19.1667 13.3806 18.0474 14.4999 16.6667 14.4999H3.33332C1.95261 14.4999 0.833323 13.3806 0.833323 11.9999V3.21124C0.833228 3.20399 0.833228 3.19673 0.833323 3.18948V2.83325C0.833323 1.45254 1.95261 0.333252 3.33332 0.333252ZM2.49999 4.80163V11.9999C2.49999 12.4602 2.87309 12.8333 3.33332 12.8333H16.6667C17.1269 12.8333 17.5 12.4602 17.5 11.9999V4.80163L11.4336 9.04807C10.5728 9.65063 9.42713 9.65063 8.56633 9.04807L2.49999 4.80163ZM17.4975 2.76892L10.4779 7.68268C10.1909 7.88354 9.80904 7.88354 9.5221 7.68268L2.50244 2.76892C2.53528 2.33874 2.89473 1.99992 3.33332 1.99992H16.6667C17.1052 1.99992 17.4647 2.33874 17.4975 2.76892Z"
        fill="text"
      />
    </Svg>
  )
}

export default Icon
