import { useTranslation } from '@pancakeswap/localization'
import { Flex, Menu as UikitMenu } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'
import UserMenu from './UserMenu'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const Menu = (props) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const { theme } = useTheme()
  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])
  
  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <CsWrapHeader>
            <NetworkSwitcher isHiddenText={!false} />
            <UserMenu />
          </CsWrapHeader>
        }
        isDark={!false}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        {...props}
      />
    </>
  )
}
const CsWrapHeader = styled(Flex)`
  width: auto;
  padding: 8px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.wrapHeader};
  @media screen and (max-width: 600px){
    max-width: 180px;
    padding: 6px;
  }
`
export default Menu
