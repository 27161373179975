import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="17" height="20" viewBox="0 0 17 20" style={{fill:"none"}} {...props}>
<path d="M10.7483 1.00027C10.7483 1.49193 10.7483 1.98358 10.7483 2.49615C10.2504 2.49615 9.76145 2.49615 9.24562 2.49615C9.24562 2.67163 9.24562 2.82754 9.24562 2.98319C9.24562 3.28769 9.24888 3.59246 9.24399 3.89696C9.24236 3.99638 9.26843 4.04364 9.376 4.05587C10.61 4.19521 11.7343 4.6282 12.7567 5.33009C12.7711 5.33987 12.7869 5.34802 12.8029 5.35753C13.1813 4.97589 13.5591 4.59479 13.9267 4.22374C14.2993 4.59397 14.647 4.93921 14.998 5.28772C14.6546 5.6297 14.2991 5.98391 13.9443 6.33704C16.8899 9.46706 16.5696 14.2828 13.5738 17.0255C10.698 19.6582 6.30085 19.6584 3.4229 17.0247C0.428424 14.2847 0.104365 9.46842 3.04153 6.34899C2.68868 5.99994 2.33556 5.65062 1.9952 5.31379C2.35348 4.95768 2.70036 4.61353 3.04941 4.26665C3.39845 4.61896 3.77548 4.99925 4.14381 5.37084C4.49014 5.17065 4.796 4.97751 5.11626 4.81209C5.89802 4.4079 6.72867 4.158 7.60333 4.05152C7.65602 4.045 7.7443 3.98578 7.74485 3.94938C7.75435 3.4762 7.75109 3.00275 7.75109 2.5081C7.24776 2.5081 6.75963 2.5081 6.24897 2.5081C6.24897 1.99119 6.24897 1.49573 6.24897 1C7.74838 1.00027 9.24833 1.00027 10.7483 1.00027Z" stroke="#5155F6" strokeWidth="1.2"/>
<path d="M4.01717 11.4989C5.50925 11.4989 6.99019 11.4989 8.49341 11.4989C8.49341 10.0003 8.49341 8.51144 8.49341 7.02616C9.85754 6.8113 11.7315 7.94726 12.4989 9.43717C13.3817 11.1509 13.0718 13.2468 11.7318 14.6251C10.3677 16.0283 8.28642 16.3983 6.52434 15.5391C4.64383 14.6221 3.95796 12.7522 4.01717 11.4989Z" stroke="#5155F6" strokeWidth="1.2"/>

    </Svg>
  );
};

export default Icon;
