import { createReducer } from '@reduxjs/toolkit';
import {
    fetchActionActiveTab, fetchCurrent, fetchTxHash, fetchDataMint, fetchDataHistory
} from './actions';
import { ACTION_ACTIVETAB, CurrentType, DataMint, HistoryType } from './types';

interface global {
    activeTab: number;
    dataCurrent:CurrentType,
    txHash:string,
    dataMint: DataMint,
    dataHistory:HistoryType
}
export const initialState: global = {
    activeTab: ACTION_ACTIVETAB.MINT,
    dataCurrent: null,
    txHash: "",
    dataMint: null,
    dataHistory: {
        orders: null,
        totalItem: 0
    }
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchActionActiveTab, (state, action) => {
            state.activeTab = action.payload.activeTab
        })
        .addCase(fetchCurrent, (state, action) => {
            state.dataCurrent = action.payload.dataCurrent
        })
        .addCase(fetchTxHash, (state, action) => {
            state.txHash = action.payload.txHash
        })
        .addCase(fetchDataMint, (state, action) => {
            state.dataMint = action.payload.dataMint
        })
        .addCase(fetchDataHistory, (state, action) => {
            state.dataHistory = action.payload.dataHistory
        })
)