import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 36 36" {...props} style={{fill:"none"}}>
  <path d="M17.9999 32.7611C26.119 32.7611 32.7618 26.1182 32.7618 17.9992C32.7618 9.88015 26.119 3.23729 17.9999 3.23729C9.88089 3.23729 3.23804 9.88015 3.23804 17.9992C3.23804 26.1182 9.88089 32.7611 17.9999 32.7611Z" fill="#49DB04" stroke="#49DB04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.7261 17.9992L15.9037 22.1768L24.2737 13.8216" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
