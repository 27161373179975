import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 254 10" fill="none" {...props}>
<path d="M103.481 3.10156H5.15717V4.15903H103.481V3.10156Z" fill="#494949"/>
<path d="M5.15712 3.63322C5.15712 4.95506 4.01109 6.01253 2.57856 6.01253C1.14603 6.01253 0 4.95506 0 3.63322C0 2.31138 1.14603 1.25391 2.57856 1.25391C4.01109 1.25391 5.15712 2.31138 5.15712 3.63322Z" fill="#494949"/>
<path d="M150.519 3.10156H248.843V4.15903H150.519V3.10156Z" fill="#494949"/>
<path d="M248.843 3.63322C248.843 4.95506 249.989 6.01253 251.421 6.01253C252.854 6.01253 254 4.95506 254 3.63322C254 2.31138 252.854 1.25391 251.421 1.25391C249.989 1.25391 248.843 2.31138 248.843 3.63322Z" fill="#494949"/>
<path fill="none" d="M136 1L126.5 9L117 1" stroke="#494949" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
