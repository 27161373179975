import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 60 60" fill="none" {...props}>
        <path d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5ZM41.6 22C41.225 25.95 39.6 35.55 38.775 39.975C38.425 41.85 37.725 42.475 37.075 42.55C35.625 42.675 34.525 41.6 33.125 40.675C30.925 39.225 29.675 38.325 27.55 36.925C25.075 35.3 26.675 34.4 28.1 32.95C28.475 32.575 34.875 26.75 35 26.225C35.0174 26.1455 35.0151 26.0629 34.9933 25.9845C34.9715 25.9061 34.9309 25.8342 34.875 25.775C34.725 25.65 34.525 25.7 34.35 25.725C34.125 25.775 30.625 28.1 23.8 32.7C22.8 33.375 21.9 33.725 21.1 33.7C20.2 33.675 18.5 33.2 17.225 32.775C15.65 32.275 14.425 32 14.525 31.125C14.575 30.675 15.2 30.225 16.375 29.75C23.675 26.575 28.525 24.475 30.95 23.475C37.9 20.575 39.325 20.075 40.275 20.075C40.475 20.075 40.95 20.125 41.25 20.375C41.5 20.575 41.575 20.85 41.6 21.05C41.575 21.2 41.625 21.65 41.6 22Z" fill="#28A7E7"/>
    </Svg>
  );
};

export default Icon;
