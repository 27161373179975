import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.MD]: {
    height: "48px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    background: "linear-gradient(90deg, #F1A30C 0%, #FEEA38 100%)",
    color: "white",
  },
  [variants.SECONDARY]: {
    background: "transparent",
    border: "2px solid",
    borderColor: "gold", 
    boxShadow: "none",
    color: "gold",
    ":disabled": {
      background: "backgroundDisabled",
      color:"white",
      border:"none"
    },
    ":hover": {
      background: "linear-gradient(90deg, #F1A30C 0%, #FEEA38 100%)",
      color:"white",
    },
  },
  [variants.TERTIARY]: {
    background: "tertiary",
    boxShadow: "none",
    color: "text",
  },
  [variants.SUBTLE]: {
    background: "textSubtle",
    color: "backgroundAlt",
  },
  [variants.DANGER]: {
    background: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    background: "success",
    color: "white",
  },
  [variants.TEXT]: {
    background: "transparent",
    color: "gold",
    boxShadow: "none",
    padding:"0px",
    ":disabled": {
      background: "transparent",
      color: "gold",
    },
  },
  [variants.LIGHT]: {
    background: "input",
    color: "textSubtle",
    boxShadow: "none",
  },
};
