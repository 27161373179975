import { createReducer } from '@reduxjs/toolkit';
import {
    fetchActiveTab
} from './actions';
import { ACTIVETAB } from './types';

interface global {
    activeTab: string;
}
export const initialState: global = {
    activeTab: ACTIVETAB.ALL
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchActiveTab, (state, action) => {
            state.activeTab = action.payload.activeTab
        })
)