import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" fill="none" {...props}>
        <path fill="none" d="M13.6668 5.33203V12.6654H4.3335V1.33203H10.0002M13.6668 5.33203H10.0002V1.33203M13.6668 5.33203L10.0002 1.33203" stroke="#494949" strokeLinecap="round" strokeLinejoin="round"/>
        <path fill="none" d="M2.3335 6.66406V14.6641H9.3335" stroke="#494949" strokeLinecap="round" strokeLinejoin="round"/>
        <path fill="none" d="M6.3335 6.66406H7.66683M6.3335 9.33073H10.3335" stroke="#494949" strokeLinecap="round"/>
    </Svg>
  );
};

export default Icon;
