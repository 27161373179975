import React from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import Svg from '../Svg'
import { SvgProps } from '../types'

const CsSvg = styled(Svg)`
  border: 1px solid #ccc;
  width: 10px;
  border-radius: 50%;

  &:hover {
    background: #ccc;
  }
`

const Icon: React.FC<SvgProps> = (props) => {
  const { theme } = useTheme()
  return (
    <CsSvg viewBox="0 0 10 6" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.20711 0.792893C8.81658 0.402369 8.18342 0.402369 7.79289 0.792893L5 3.58579L2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792894 0.792893C0.402369 1.18342 0.402369 1.81658 0.792894 2.20711L4.29289 5.70711C4.68342 6.09763 5.31658 6.09763 5.70711 5.70711L9.20711 2.20711C9.59763 1.81658 9.59763 1.18342 9.20711 0.792893Z" fill="#494949"/>
    </CsSvg>
    
  )
}

export default Icon
