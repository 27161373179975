import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.92004 7.89014L12.39 10.3501"
      stroke="#EEB063"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.92004 7.88992L12.39 5.41992"
      stroke="#EEB063"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 16.1099L11.59 13.6499"
      stroke="#EEB063"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 16.1099L11.59 18.5799"
      stroke="#EEB063"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.05 16.1101H6.86C4.59 16.1101 2.75 14.2701 2.75 12.0001C2.75 9.73012 4.59 7.89014 6.86 7.89014"
      stroke="#EEB063"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.97 7.89014H17.14C19.41 7.89014 21.25 9.73012 21.25 12.0001C21.25 14.2701 19.41 16.1101 17.14 16.1101"
      stroke="#EEB063"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
