import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M14.9 22.75H9.10001C8.21001 22.75 7.07 22.28 6.45 21.65L2.35001 17.55C1.72001 16.92 1.25 15.78 1.25 14.9V9.10001C1.25 8.21001 1.72001 7.07001 2.35001 6.45001L6.45 2.35001C7.08 1.72001 8.22001 1.25 9.10001 1.25H14.9C15.79 1.25 16.93 1.72001 17.55 2.35001L21.65 6.45001C22.28 7.08001 22.75 8.22001 22.75 9.10001V14.9C22.75 15.79 22.28 16.93 21.65 17.55L17.55 21.65C16.92 22.28 15.79 22.75 14.9 22.75ZM9.10001 2.75C8.61001 2.75 7.84999 3.06 7.50999 3.41L3.41 7.51001C3.07 7.86001 2.75 8.61001 2.75 9.10001V14.9C2.75 15.39 3.06 16.15 3.41 16.49L7.50999 20.59C7.85999 20.93 8.61001 21.25 9.10001 21.25H14.9C15.39 21.25 16.15 20.94 16.49 20.59L20.59 16.49C20.93 16.14 21.25 15.39 21.25 14.9V9.10001C21.25 8.61001 20.94 7.85001 20.59 7.51001L16.49 3.41C16.14 3.07 15.39 2.75 14.9 2.75H9.10001Z"/>
      <path d="M8.49994 16.2495C8.30994 16.2495 8.11994 16.1795 7.96994 16.0295C7.67994 15.7395 7.67994 15.2595 7.96994 14.9695L14.9699 7.96945C15.2599 7.67945 15.7399 7.67945 16.0299 7.96945C16.3199 8.25945 16.3199 8.73945 16.0299 9.02945L9.02994 16.0295C8.87994 16.1795 8.68994 16.2495 8.49994 16.2495Z" />
      <path d="M15.4999 16.2495C15.3099 16.2495 15.1199 16.1795 14.9699 16.0295L7.96994 9.02945C7.67994 8.73945 7.67994 8.25945 7.96994 7.96945C8.25994 7.67945 8.73994 7.67945 9.02994 7.96945L16.0299 14.9695C16.3199 15.2595 16.3199 15.7395 16.0299 16.0295C15.8799 16.1795 15.6899 16.2495 15.4999 16.2495Z" />
    </Svg>
  );
};

export default Icon;
