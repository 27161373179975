import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" fill="none" {...props}>
<path fill="none" d="M11.25 14.9386L6.36 10.0486C5.7825 9.47109 5.7825 8.52609 6.36 7.94859L11.25 3.05859" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
