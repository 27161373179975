import { Token } from "@pancakeswap/sdk"

export const ACTION_ACTIVETAB = {
    MINT: 0,
    REDEEM: 1,
}

export interface CURRENTITEM {
    currentInput: Token,
    currentOutput: Token,
}

export interface DataUser {
    balanceOf:string,
    allowance: string
}

export interface CurrentType {
    currentIn: {
        token: Token,
        dataUser: DataUser
    },
    currentOut: {
        token: Token,
        dataUser: DataUser
    }
}

export interface DataMint {
    feeWithdraw: string,
    mintAmountMint: string,
    isPaused: boolean,
    isWhitelist: boolean,
    totalVndcCanRedeem: string,
    baseRate: string,
    pairAddress: string,
    reserve0: string,
    reserve1: string,
    totalSupply:number,
    lpBalance:string,
    balanceOfCurrency: number,
    lpPerUser: string,
    totalLp: string
}

export interface OrderItem {
    _id: string,
    sender: string,
    method: string,
    transactionHash: string,
    vndcAmount: string,
    usdcAmount: string,
    chainId: string,
    blockNumber: string,
    __v: string,
    createdAt: string,
    updatedAt: string,
    timeStamp: number
}


export interface HistoryType {
    orders: OrderItem[],
    totalItem: number
}